.pledge-button {
  display: inline-block !important;
  width: 500px; /* Set a fixed width for the buttons */
  height: 90px; /* Set a fixed height for the buttons */
  padding: 0;
  margin: 10px; /* Space between buttons */
  background-color: #20bf6b !important;
  border: 2px solid #333; /* Black border */
  color: #333; /* Text color */
  font-size: 2rem !important; /* Font size */
  font-weight: bold !important; /* Bold text */
  text-align: center; /* Center text */
  vertical-align: middle; /* Vertically center text */
  line-height: 90px; /* Match the line height to the height to center text */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth background color transition */
  font-family: "Speedee Bold" !important;
}

.add-pledges .stylization button, .stylization input[type=submit] {
  padding: 0;
  vertical-align: middle; /* Vertically center text */
}

.pledge-button:hover {
  background-color: #26de81 !important; /* Darker yellow on hover */
}

.custom-info-button {
    display: inline-block !important;
    width: 500px; /* Set a fixed width for the buttons */
    height: 100px; /* Set a fixed height for the buttons */
    margin: 10px; /* Space between buttons */
    background-color: #fa8231 !important;
    border: 2px solid white; /* Black border */
    color: white; /* Text color */
    font-size: 2rem !important; /* Font size */
    font-weight: bolder !important; /* Bold text */
    text-align: center; /* Center text */
    vertical-align: middle; /* Vertically center text */
    line-height: 100px; /* Match the line height to the height to center text */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth background color transition */
    outline: none;
  }
  
  /* Media query for iPhone screen sizes */
@media screen and (max-width: 480px) {
  .custom-info-button {
    width: 100%; /* Set width to 100% */
    font-size: 1.5rem !important; /* Reduce the font size */
    line-height: normal; /* Adjust line height */
    min-height: 60px; /* Allow height to adjust based on content */
    height: auto;
    padding: 20px; /* Add padding for better touch targets */
  }
}
  
  .custom-info-button:hover {
    color: white;
    background-color: #fd9644 !important; /* Darker yellow on hover */
    outline: none;
  }

  .custom-info-button:active, 
  .custom-info-button:focus {
      background-color: #ff9f43 !important; /* Slightly lighter color when pressed */
      transform: scale(0.95); /* Slightly shrink the button to simulate a press */
      color: white;
      outline: none !important;
  }

  .auth-submit .custom-submit-button {
    display: inline-block !important;
    width: 500px; /* Set a fixed width for the buttons */
    height: 80px; /* Set a fixed height for the buttons */
    margin-top: 15px; /* Space between buttons */
    background-color: #eb3b5a !important;
    color: white; /* Text color */
    font-size: 2rem !important; /* Font size */
    font-weight: bolder !important; /* Bold text */
    text-align: center; /* Center text */
    vertical-align: middle; /* Vertically center text */
    line-height: 80px; /* Match the line height to the height to center text */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth background color transition */
  }
  
  .auth-submit .custom-submit-button:hover {
    background-color: #fc5c65 !important; /* Darker yellow on hover */
  }

  .auth-submit .custom-submit-button:active, 
.auth-submit .custom-submit-button:focus {
    background-color: #ff6b6b !important; /* Slightly lighter color when pressed */
    transform: scale(0.95); /* Slightly shrink the button to simulate a press */
    outline: none;
}

  .auth-submit .custom-submit-button.submit-button {
    background-color: #f7b731 !important;
  }

  .auth-submit .custom-submit-button.submit-button:hover {
    background-color: #fed330 !important;
  }

  .auth-submit .custom-submit-button.submit-button:active, 
.auth-submit .custom-submit-button.submit-button:focus {
    background-color: #ffcc00 !important; /* Slightly lighter color when pressed */
    transform: scale(0.95); /* Slightly shrink the button to simulate a press */
    outline: none;
}
  
  

.add-pledges h1.main-ttl {
    font-size: 30px;
  }
  